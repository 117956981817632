import { Suspense } from "react";
import { Await, useLoaderData } from "react-router-dom";
import { PrimaryLink } from "@/components/primary-link";
import SecondaryLink from "@/components/secondary-link";
import { InvoiceDetailsData } from "./loader";

export function BillingDetailsBanner() {
  const { billingDetails } = useLoaderData() as InvoiceDetailsData;

  return (
    <>
      <div className="flex gap-2 md:flex-wrap items-center p-4 rounded border border-red-400 bg-rose-50">
        <p className="flex-1 text-red-700 text-sm">
          <b>The invoice is missing billing details</b> and cannot be sent until these are provided.
        </p>

        <Suspense fallback="Request details">
          <Await resolve={billingDetails}>
            {(resolvedBillingDetails) => (
              <div className="md:flex md:gap-2 md:w-auto">
                {resolvedBillingDetails.length > 0 && (
                  <SecondaryLink to="select-billing">Select details</SecondaryLink>
                )}

                <PrimaryLink to="request-billing">
                  {resolvedBillingDetails.length > 0 ? "Request new details" : "Request details"}
                </PrimaryLink>
              </div>
            )}
          </Await>
        </Suspense>
      </div>
    </>
  );
}
