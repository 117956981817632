import { Suspense } from "react";
import { Await, useLoaderData } from "react-router-dom";
import { MoneyDisplay } from "@/components/money-display";
import { OrderItemProductionStatusPill } from "@/components/order-item-production-status-pill";
import { OrderItemSetupStatusPill } from "@/components/order-item-setup-status-pill";
import SecondaryLink from "@/components/secondary-link";
import StyledLink from "@/components/styled-link";
import { Table, TableColumn, TableRow } from "@/components/table";
import { Order } from "@/entities/order";
import { OrderItem } from "@/entities/order-item";
import { OrderItemStatus } from "@/entities/order-item-status";
import { OrderDetailsData } from "@/routes/orders.$order/route";

export function OrderItems() {
  const { order } = useLoaderData() as OrderDetailsData;

  return (
    <Table
      columns={[
        { label: "Item #" },
        { label: "Invoice" },
        { label: "Setup status" },
        { label: "Production status" },
        { label: "Product" },
        { label: "Price", rightAligned: true },
        { label: "" },
      ]}
    >
      <tbody>
      {order.items.map((item) => (
        <OrderItemRow key={item.id} item={item} order={order} />
      ))}

      {order.items.length === 0 && (
        <tr>
          <td colSpan={5} className="p-4 italic">
            This order does not have any items.
          </td>
        </tr>
      )}
      </tbody>

      {order.items.length && (
        <tfoot>
        <tr className="bg-white border-t">
          <td colSpan={5} className="p-4 text-right font-bold">
            Totals
          </td>

          <td className="p-4 text-right">
            <MoneyDisplay amount={order.itemsTotal} />
          </td>
          <td className="p-4"></td>
        </tr>
        </tfoot>
      )}
    </Table>
  );
}

function OrderItemRow({ item, order }: { item: OrderItem; order: Order }) {
  const { canUserModify } = useLoaderData() as OrderDetailsData;
  // using [...order.invoices] to reverse array copy and avoid mutating original array. findLast() or toReversed() methods are supported in TS from 5+ version
  const invoice = [...order.invoices]
    .reverse()
    .find((invoice) => invoice.items.find((invoiceItem) => invoiceItem.orderItemId === item.id));

  return (
    <TableRow>
      <TableColumn>
        <StyledLink to={`/order-items/${item.id}`}>
          #{item.numericId}
        </StyledLink>
      </TableColumn>

      <TableColumn>
        {invoice?.numericId ? (
          <StyledLink className="font-bold" to={`/invoices/${invoice?.id}`}>
            #{invoice.numericId}
          </StyledLink>
        ) : (
          "-"
        )}
      </TableColumn>

      <TableColumn>
        {item.status == OrderItemStatus.Cancelled ? (
          "-"
        ) : item.isDistributed ? (
          <div className="flex flex-wrap gap-2 items-center whitespace-nowrap">
            Distributed to {item.sentTo ? item.sentTo.companyName : "related company"}
          </div>
        ) : (
          item.product?.isTargetProduct && (
            <OrderItemSetupStatusPill
              linkProps={{
                state: { back_to: { order: { id: order.id, numericId: order.numericId } } },
              }}
              item={item}
            />
          )
        )}
      </TableColumn>

      <TableColumn>
        <div className="flex flex-wrap gap-2 items-center">
          {item.isDistributed ? (
            <div className="flex flex-wrap gap-2 items-center whitespace-nowrap">-</div>
          ) : (
            <OrderItemProductionStatusPill
              linkProps={{
                state: { back_to: { order: { id: order.id, numericId: order.numericId } } },
              }}
              item={item}
            />
          )}
        </div>
      </TableColumn>

      <TableColumn>
        <div className="flex flex-wrap gap-2 items-center whitespace-nowrap">
          {item.product?.title ?? <em>Deleted product</em>}
        </div>
      </TableColumn>

      <TableColumn numeric rightAligned>
        <MoneyDisplay amount={item.amount} />
      </TableColumn>

      <TableColumn className="items-center flex gap-x-4">
        <Suspense fallback={null}>
          <Await resolve={canUserModify}>
            {(hasPermission) => (
              <SecondaryLink
                disabled={
                  !hasPermission ||
                  !item.canBeEdited ||
                  !invoice?.canBeEdited
                }
                disabledTooltipText={
                  !hasPermission && "You don't have permission to edit this order item"
                  || !item.canBeEdited && item.cannotBeActedOnMessage()
                  || !invoice?.canBeEdited && invoice?.cannotBeActedOnMessage()
                }
                to={`/order-items/${item.id}/edit-details`} preventScrollReset size="lg"
              >
                Edit
              </SecondaryLink>
            )}
          </Await>
        </Suspense>
      </TableColumn>
    </TableRow>
  );
}
