import { Form, useLoaderData, useSearchParams, useSubmit } from "react-router-dom";
import SelectBox from "@/components/forms/select-box";
import { InvoiceStatus } from "@/entities/invoice-status";
import { Unit } from "@/entities/unit";
import { InvoiceListData } from "@/routes/invoices/route";
import { FailedOptionalResult } from "@/helpers/loader-optional";
import { snakeCaseToName } from "@/helpers/snake-case-to-name";

export default function InvoicesIndexFilters() {
  const [searchParams] = useSearchParams();
  const { businessUnits } = useLoaderData() as InvoiceListData;
  const submit = useSubmit();

  return (
    <Form action="/invoices" className="flex gap-x-2">
      <input type="hidden" name="page" value={1} />

      {businessUnits instanceof FailedOptionalResult ? (
        <div>Could not load business units</div>
      ) : (
        <SelectBox
          className="w-64"
          name="unit"
          value={searchParams.get("unit") ?? ""}
          label="Business Unit"
          onChange={(event) => submit(event.currentTarget.form)}
        >
          <option value="">All</option>
          {businessUnits.map((unit: Unit) => (
            <option key={unit.id} value={unit.id}>
              {unit.name}
            </option>
          ))}
        </SelectBox>
      )}

      <SelectBox
        className="w-64"
        name="status"
        value={searchParams.get("status") ?? "ready_to_send"}
        label="Status"
        onChange={(event) => submit(event.currentTarget.form)}
      >
        <option value="">All</option>
        {Object.values(InvoiceStatus).map((status: string) => (
          <option key={status} value={status}>
            {snakeCaseToName(status)}
          </option>
        ))}
      </SelectBox>

      <div className="flex-1" />

      <SelectBox
        className="w-64"
        name="order_direction"
        value={searchParams.get("order_direction") ?? "desc"}
        label="Sort"
        onChange={(event) => submit(event.currentTarget.form)}
      >
        <option value="asc">Least recently updated</option>
        <option value="desc">Most recently updated</option>
      </SelectBox>
    </Form>
  );
}
