import { FormEvent, useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { motion } from "framer-motion";
import * as Popover from "@radix-ui/react-popover";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Billing } from "@/entities/billing";
import { useDebouncedCallback } from "@/hooks/useDebouncedCallback";
import { lookupCompanyDataByVAT } from "@/api/company";
import { Label } from "@/components/forms/label";
import RequiredAsterisk from "@/components/forms/required-asterisk";
import { SpinnerIcon } from "@/components/icons/spinner";
import SecondaryButton from "@/components/secondary-button";

export type VatFieldProps = {
  billing?: Billing;
  disabled?: boolean;
  errors?: string[];
  onResultFound: (billing: Billing, override?: boolean) => void;
  required?: boolean;
};

export function VatField({ billing, disabled, errors, onResultFound, required }: VatFieldProps) {
  const [isSearching, setIsSearching] = useState(false);
  const [hasFoundResults, setHasFoundResults] = useState<boolean | null>(null);
  const [results, setResults] = useState<Billing>();
  const [isPopoverShown, setIsShownPopover] = useState(false);

  const reformatFinnishVat = useCallback((event: FormEvent<HTMLInputElement>) => {
    event.currentTarget.value = event.currentTarget.value.replace(
      /^\s*(\d{7})-(\d)\s*$/g,
      "FI$1$2",
    );
  }, []);

  const transformVatToUppercase = useCallback((event: FormEvent<HTMLInputElement>) => {
    event.currentTarget.value = event.currentTarget.value.toUpperCase();
  }, []);

  useEffect(
    () => {
      if (results && onResultFound) {
        onResultFound(results);
      }
    },
    // Intentionally omitted `onResultFound` from the dependency array,
    // because we don't want to push the results to the form
    // every time the function changes.
    [results],
  );

  const searchForBilling = useDebouncedCallback(
    { wait: 300, trailing: true },
    async (vat: string) => {
      try {
        setIsSearching(true);
        setHasFoundResults(null);

        const billing = await lookupCompanyDataByVAT(vat);

        if (!billing.name) {
          setHasFoundResults(false);
          return;
        }

        setHasFoundResults(true);
        setResults(billing);
      } catch (error) {
        setHasFoundResults(false);
      } finally {
        setIsSearching(false);
      }
    },
    [billing, onResultFound],
  );

  const onVatChange = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      reformatFinnishVat(event);
      transformVatToUppercase(event);

      if (!event.currentTarget.value.trim() || event.currentTarget.value.search(/\d+/) === -1) {
        setHasFoundResults(null);
        setResults(undefined);
        return;
      }

      searchForBilling(event.currentTarget.value);
    },
    [reformatFinnishVat, searchForBilling],
  );

  const onResultsConfirmed = useCallback(() => {
    if (results) {
      onResultFound?.(results, true);
    }

    setIsShownPopover(false);
  }, [results, onResultFound, setIsShownPopover]);

  return (
    <div>
      <Label htmlFor="business/vat_id">
        Business ID / VAT ID
        {required && <RequiredAsterisk />}
      </Label>

      <div
        className={clsx(
          "relative flex",
          "rounded-md border-0 ring-1 focus-within:ring-1 focus-within:ring-purple-500 focus-within:outline-0 transition duration-200",
          errors?.length ? "ring-red-600 bg-red-50" : "ring-stone-300 focus-within:bg-purple-50",
          disabled && "bg-stone-50 cursor-not-allowed text-stone-500",
        )}
      >
        <input
          type="text"
          name="vat"
          id="business/vat_id"
          autoComplete="off"
          autoCapitalize="off"
          className="bg-transparent border-0 w-full rounded-md focus:ring-0 focus:outline-0"
          disabled={disabled}
          defaultValue={billing?.vat}
          onInput={onVatChange}
        />

        {isSearching && (
          <div className="self-center px-2.5">
            <SpinnerIcon className="w-5 h-5 animate-spin" />
          </div>
        )}

        {hasFoundResults !== null && (
          <div className="self-center px-2.5">
            {hasFoundResults ? (
              <Popover.Root modal={false} open={isPopoverShown} onOpenChange={setIsShownPopover}>
                <Popover.Trigger className="flex bg-green-200 py-0.5 px-1.5 rounded text-green-600">
                  <span className="text-xs uppercase whitespace-nowrap font-bold tracking-tighter">
                    Found
                  </span>
                  <ChevronDownIcon className="w-4 h-4 inline-block animate-bounce" />
                </Popover.Trigger>

                <Popover.Content align="end" sideOffset={2}>
                  <Popover.Arrow className="fill-gray-200" />
                  <div className="bg-white rounded-md shadow-lg p-4 border max-w-xs">
                    <dl>
                      <dt className="text-sm font-medium text-gray-500">Name</dt>
                      <dd className="mt-1 text-sm text-gray-900">{results?.name}</dd>
                      <dt className="mt-4 text-sm font-medium text-gray-500">Address</dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {results?.address.line1}
                        <br />
                        {results?.address.postalCode} {results?.address.city}{" "}
                        {results?.address.country}
                      </dd>
                    </dl>

                    <div className="mt-4 flex flex-col gap-2">
                      <p className="text-sm text-zinc-500">
                        Would you like to add the information above to the billing form?
                        <br />
                        This action will <b>override</b> current values in the fields.
                      </p>

                      <SecondaryButton type="button" size="sm" onClick={onResultsConfirmed}>
                        Yes, add to form
                      </SecondaryButton>
                    </div>
                  </div>
                </Popover.Content>
              </Popover.Root>
            ) : (
              <motion.span
                className="inline-block bg-red-200 py-0.5 px-1.5 rounded text-red-600 text-xs uppercase whitespace-nowrap font-bold tracking-tighter"
                initial={{ x: 0 }}
                animate={{ x: [8, -8, 8, -8, 8, -8, 8, 0] }}
                transition={{ duration: 0.6 }}
              >
                No match
              </motion.span>
            )}
          </div>
        )}
      </div>

      {errors?.map((errorText) => (
        <p className="text-sm mt-2 text-red-700 font-medium" key={errorText}>
          {errorText}
        </p>
      ))}

      <p className="text-sm mt-2 text-stone-500">
        Search company information using Business ID (Y-tunnus) or VAT ID, e.g FI26558985.
      </p>
    </div>
  );
}
