import {useFetcher, useLoaderData} from "react-router-dom";
import {capitalize} from "lodash";
import {useCallback, useMemo} from "react";
import {OrderItemDetailsData} from "@/routes/order-items.$orderItem/route";
import {OrderItemStatus} from "@/entities/order-item-status";
import SecondaryButton from "@/components/secondary-button";
import {SpinnerIcon} from "@/components/icons/spinner";

export function StatusSwitcher() {
  const {orderItem} = useLoaderData() as OrderItemDetailsData;
  const fetcher = useFetcher();

  const hasValidationErrors = fetcher.data?.status === 422;

  const statuses = useMemo(() => Object.values(OrderItemStatus), []);
  const previousStatus = useMemo(
    () => statuses[statuses.indexOf(orderItem.status) - 1],
    [statuses, orderItem.status],
  );
  const nextStatus = useMemo(
    () => statuses[statuses.indexOf(orderItem.status) + 1],
    [statuses, orderItem.status],
  );

  const allowedStatuses = useMemo(
    () => Object.values([
      OrderItemStatus.New,
      OrderItemStatus.Setup,
      OrderItemStatus.Active,
      OrderItemStatus.Completed,
    ]), []);

  const isAllowedToSwitchToStatus = useCallback((status: OrderItemStatus) => {
    return allowedStatuses.includes(status);
  }, [orderItem.status]);

  return (
    <div>
      <fetcher.Form
        action={`/order-items/${orderItem.id}/status`}
        method="put"
        noValidate
        className="flex items-end gap-2"
      >
        {(previousStatus && previousStatus !== OrderItemStatus.Transferred) && (
          <SecondaryButton
            size="lg"
            disabled={
              !previousStatus
              || orderItem.isDistributedOrCancelled
              || !isAllowedToSwitchToStatus(previousStatus)
              || fetcher.state !== "idle"
            }
            name="status"
            value={previousStatus}
          >
            {previousStatus ? `Return to ${capitalize(previousStatus)}` :
              <em>No previous status</em>}
          </SecondaryButton>
        )}

        <div>
          <p className="text-sm text-center font-medium text-gray-800 mb-1">
            Current production status
          </p>
          <div
            className="border border-indigo-500 py-2.5 px-6 leading-none tracking-wide rounded-lg text-center">
            {fetcher.state !== "idle" ? (
              <div className="flex items-center">
                <SpinnerIcon className="animate-spin h-4 w-4 mr-2"/>
                <em>Moving to {capitalize(fetcher.formData?.get("status") as string)}</em>
              </div>
            ) : (
              capitalize(orderItem.status)
            )}
          </div>
        </div>

        {(nextStatus && nextStatus !== OrderItemStatus.Transferred) && (
          <SecondaryButton
            size="lg"
            disabled={
              !nextStatus
              || orderItem.isDistributedOrCancelled
              || !isAllowedToSwitchToStatus(nextStatus)
              || fetcher.state !== "idle"
            }
            name="status"
            value={nextStatus}
          >
            {nextStatus ? `Move to ${capitalize(nextStatus)}` : <em>No next status</em>}
          </SecondaryButton>
        )}
      </fetcher.Form>

      {hasValidationErrors && <p className="mt-1 text-red-600">{fetcher.data?.message}</p>}
    </div>
  );
}
