import { useContext } from "react";
import { Money } from "@/entities/money";
import { LocaleContext } from "@/helpers/locale-context";

export type MoneyDisplayProps = {
  amount: Money;
  numbersOnly?: boolean;
};

export function MoneyDisplay({ amount, numbersOnly }: MoneyDisplayProps) {
  const { locale } = useContext(LocaleContext);

  const number = new Intl.NumberFormat(locale, {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount.amountWithoutCents);

  return <>{numbersOnly ? number : `${number} ${amount.currency.toUpperCase()}`}</>;
}
