import {
  Await,
  defer,
  json,
  LoaderFunctionArgs,
  useLoaderData,
  useNavigation,
} from "react-router-dom";
import { Suspense, useRef } from "react";
import TextField from "@/components/forms/text-field";
import { Label } from "@/components/forms/label";
import { RichRadio } from "@/components/forms/rich-radio";
import SelectBox from "@/components/forms/select-box";
import { TextareaField } from "@/components/forms/textarea-field";
import { fetchAllUnits } from "@/api/unit";
import { fetchOrderItem } from "@/api/order-item";
import { fetchCompanyTokens } from "@/api/consumable-token";
import { OrderItem } from "@/entities/order-item";
import { Unit } from "@/entities/unit";
import { useActiveActionData } from "@/hooks/useActiveActionData";
import SubmitButton from "@/components/submit-button";
import * as Page from "@/components/page";
import { ValidatedForm } from "@/components/forms/validated-form";
import { assertParameterExists } from "@/helpers/loader-guards";
import { GoBackLink } from "./go-back-link";
import { CampaignRequestPageActionData, formSchema } from "./action";
import { AdditionalInformation } from "./additional-information";
import { Booster } from "./booster";

export { action } from "./action";
export { ErrorBoundary } from "./error-boundary";

export type CampaignRequestPageLoaderData = {
  orderItem: OrderItem;
  units: Promise<Unit[]>;
  countAvailableRangeToken: Promise<number>;
  countAvailableDurationToken: Promise<number>;
  countAvailableCandidateContactToken: Promise<number>;
};

export async function loader({ params }: LoaderFunctionArgs) {
  assertParameterExists(params.orderItem);

  const orderItem = await fetchOrderItem(params.orderItem);

  if (!orderItem.product?.isTargetProduct) {
    throw json(
      {
        message: "This is not a digital headhunting order item.",
        description: "You can request a campaign only for a digital headhunting order item.",
      },
      { status: 422 },
    );
  }

  const tokens = orderItem.order?.company?.id
    ? fetchCompanyTokens(orderItem.order.company?.id)
    : null;

  return defer({
    orderItem,
    units: fetchAllUnits(),
    countAvailableRangeToken: tokens?.then(
      (tokens) => tokens.filter((token) => token.type === "range_booster").length,
    ),
    countAvailableDurationToken: tokens?.then(
      (tokens) => tokens.filter((token) => token.type === "duration_booster").length,
    ),
    countAvailableCandidateContactToken: tokens?.then(
      (tokens) => tokens.filter((token) => token.type === "contact_booster").length,
    ),
  });
}

export default function CampaignRequestPage() {
  const customDurationRadioRef = useRef<HTMLInputElement | null>(null);
  const customDurationInputRef = useRef<HTMLInputElement | null>(null);

  const { orderItem, units } = useLoaderData() as CampaignRequestPageLoaderData;
  const actionResult = useActiveActionData() as CampaignRequestPageActionData;

  const navigation = useNavigation();
  const isSubmitting = navigation.state !== "idle";

  return (
    <Page.Root>
      <Page.Heading backLink={<GoBackLink />}>
        <Page.Title>
          {orderItem.campaignContext ? "Edit campaign request" : "Start a new campaign"}
        </Page.Title>
      </Page.Heading>

      <p className="-mt-12">
        Provide service production the required information to start producing the campaign.
      </p>

      <Page.Content>
        <ValidatedForm method="post" noValidate formSchema={formSchema}>
          <input
            type="hidden"
            name="campaign_context_id"
            value={orderItem.campaignContext?.id ?? ""}
          />

          {orderItem.campaignContext?.jobId && (
            <input
              type="hidden"
              name="campaign_context_job_id"
              value={orderItem.campaignContext?.jobId}
            />
          )}

          <fieldset className="space-y-8" disabled={isSubmitting}>
            <TextField
              id="job_name"
              name="job_name"
              label="Campaign name"
              description="What is the job title of the campaign?"
              defaultValue={orderItem.campaignContext?.jobName}
            />

            <div>
              <Label>Campaign duration</Label>

              <div className="mt-1 space-y-3">
                <RichRadio
                  name="duration"
                  value="14"
                  defaultChecked={
                    orderItem.campaignContext ? orderItem.campaignContext.duration === 14 : true
                  }
                >
                  <p className="font-bold">2 weeks</p>
                  <p className="font-medium">Set campaign duration to 14 days.</p>
                </RichRadio>

                <RichRadio
                  name="duration"
                  value="21"
                  defaultChecked={orderItem.campaignContext?.duration === 21}
                >
                  <p className="font-bold">3 weeks</p>
                  <p className="font-medium">Set campaign duration to 21 days.</p>
                </RichRadio>

                <RichRadio
                  inputRef={customDurationRadioRef}
                  name="duration"
                  value="custom"
                  onClick={() => {
                    customDurationInputRef.current?.focus();
                  }}
                  defaultChecked={
                    orderItem.campaignContext
                      ? ![14, 21].includes(orderItem.campaignContext.duration)
                      : false
                  }
                >
                  <p className="font-bold">Custom duration (in days)</p>

                  <TextField
                    inputRef={customDurationInputRef}
                    inputMode="numeric"
                    className="mt-2 w-32"
                    name="custom_duration"
                    defaultValue={
                      orderItem.campaignContext &&
                      ![14, 21].includes(orderItem.campaignContext.duration)
                        ? orderItem.campaignContext.duration.toString()
                        : ""
                    }
                    min="1"
                    onClick={() => {
                      if (customDurationRadioRef.current) {
                        customDurationRadioRef.current.checked = true;
                      }
                    }}
                  />
                </RichRadio>
              </div>

              {actionResult?.errors?.duration?.map((errorText) => (
                <p className="text-sm mt-2 text-red-700 font-medium" key={errorText}>
                  {errorText}
                </p>
              ))}
            </div>

            <Booster />

            <TextField
              label="Job location"
              id="job_location"
              name="job_location"
              description="Where is the job located? If the campaign should be targeted to a different location, provide the details in the notes field."
              defaultValue={orderItem.campaignContext?.jobLocation ?? ""}
            />

            <Suspense
              fallback={
                <SelectBox
                  label="Assigned unit"
                  id="unit_id"
                  name="unit_id"
                  description="Select the business unit that this campaign request will be assigned to."
                  defaultValue={
                    orderItem.campaignContext?.unitId
                    ?? orderItem.order?.company?.units[0]?.id
                  }
                >
                  <option value="" disabled selected>Loading units</option>
                </SelectBox>
              }
            >
              <Await resolve={units}>
                {(resolvedUnits: Unit[]) => (
                  <SelectBox
                    label="Assigned unit"
                    id="unit_id"
                    name="unit_id"
                    description="Select the business unit that this campaign request will be assigned to."
                    defaultValue={
                      orderItem.campaignContext?.unitId
                      ?? orderItem.order?.company?.units[0]?.id
                    }
                  >
                    <option value="" disabled selected>Select a unit</option>
                    {resolvedUnits.map((unit) => (
                      <option key={unit.id} value={unit.id}>
                        {unit.name}
                      </option>
                    ))}
                  </SelectBox>
                )}
              </Await>
            </Suspense>

            <TextareaField
              label="Notes"
              id="notes"
              name="notes"
              description="Is there some additional information that campaign production should be aware of?"
              defaultValue={orderItem.campaignContext?.notes ?? ""}
            />

            <AdditionalInformation />

            <div className="flex justify-center">
              <SubmitButton
                size="lg"
                label={orderItem.campaignContext ? "Save changes" : "Request setup"}
                labelWhenSubmitting="Processing..."
              />
            </div>
          </fieldset>
        </ValidatedForm>
      </Page.Content>
    </Page.Root>
  );
}
