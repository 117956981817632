import { Link, LinkProps } from "react-router-dom";
import clsx from "clsx";
import { forwardRef, memo, useState } from "react";
import * as Tooltip from "@radix-ui/react-tooltip";
import { AnimatePresence, motion } from "framer-motion";

export type PrimaryLinkProps = LinkProps & {
  size?: "sm" | "md" | "lg" | "xl";
  disabled?: boolean;
  disabledTooltipText?: string | false;
};

export const PrimaryLink = memo(forwardRef<HTMLAnchorElement, PrimaryLinkProps>(
  function PrimaryLinkWithoutRef({
    children,
    size = "md",
    disabled,
    disabledTooltipText,
    ...props
  }: PrimaryLinkProps, ref) {
    const [isTooltipShown, setIsTooltipShown] = useState(false);

    const padding = {
      sm: "py-1.5 px-3",
      md: "py-2 px-4",
      lg: "py-3 px-6",
      xl: "py-4 px-8",
    };

    const baseClasses = [
      "inline-flex items-center justify-center gap-1",
      "bg-violet-600 text-white font-medium text-sm shadow leading-none rounded-lg transition duration-200",
      padding[size],
      "whitespace-nowrap",
      "enabled:hover:bg-gradient-to-bl enabled:hover:from-violet-900 enabled:hover:to-violet-950",
      "disabled:opacity-50 disabled:cursor-not-allowed",
    ];

    if (disabled) {
      return (
        <Tooltip.Root
          delayDuration={150}
          open={isTooltipShown}
          onOpenChange={setIsTooltipShown}
        >
          <Tooltip.Trigger asChild>
            <span className={clsx(baseClasses, "opacity-40 cursor-not-allowed")}>{children}</span>
          </Tooltip.Trigger>

          <AnimatePresence>
            {(isTooltipShown && !!disabledTooltipText) && (
              <Tooltip.Portal forceMount>
                <Tooltip.Content side="bottom" asChild forceMount>
                  <motion.div
                    initial={{ opacity: 0, y: -5 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -5 }}
                    className="inline-block bg-zinc-900 bg-opacity-90 text-white text-sm font-medium rounded py-1 px-2"
                  >
                    {disabledTooltipText}
                  </motion.div>
                </Tooltip.Content>
              </Tooltip.Portal>
            )}
          </AnimatePresence>
        </Tooltip.Root>
      );
    }

    return (
      <Link
        ref={ref}
        {...props}
        className={clsx(
          baseClasses,
          props?.className ?? "",
        )}
      >
        {children}
      </Link>
    );
  }),
);
