import { Link, LinkProps } from "react-router-dom";
import clsx from "clsx";
import * as Tooltip from "@radix-ui/react-tooltip";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";

type SecondaryLinkProps = LinkProps & {
  size?: "xs" | "sm" | "sm-square" | "md" | "lg" | "xl";
  disabled?: boolean;
  disabledTooltipText?: string | false;
};

export default function SecondaryLink({
  children,
  size = "md",
  disabled,
  disabledTooltipText,
  ...props
}: SecondaryLinkProps) {
  const [isTooltipShown, setIsTooltipShown] = useState(false);

  const sizeBasedStyles: Record<Exclude<SecondaryLinkProps["size"], undefined>, string> = {
    xs: "py-1 px-2 text-xs",
    sm: "py-1.5 px-3 text-sm",
    "sm-square": "py-1.5 px-1.5 text-sm",
    md: "py-2 px-4 text-sm",
    lg: "py-3 px-6 text-sm",
    xl: "py-4 px-8",
  };

  const baseClasses = [
    "inline-flex items-center justify-center",
    "bg-purple-200 text-violet-900 font-medium rounded-lg leading-none tracking-wide",
    sizeBasedStyles[size],
    "transition duration-200",
  ];

  if (disabled) {
    return (
      <Tooltip.Root
        delayDuration={150}
        open={isTooltipShown}
        onOpenChange={setIsTooltipShown}
      >
        <Tooltip.Trigger asChild>
          <span className={clsx(baseClasses, "opacity-40 cursor-not-allowed")}>{children}</span>
        </Tooltip.Trigger>

        <AnimatePresence>
          {(isTooltipShown && !!disabledTooltipText) && (
            <Tooltip.Portal forceMount>
              <Tooltip.Content side="bottom" asChild forceMount>
                <motion.div
                  initial={{ opacity: 0, y: -5 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -5 }}
                  className="inline-block bg-zinc-900 bg-opacity-90 text-white text-sm font-medium rounded py-1 px-2"
                >
                  {disabledTooltipText}
                </motion.div>
              </Tooltip.Content>
            </Tooltip.Portal>
          )}
        </AnimatePresence>
      </Tooltip.Root>
    );
  }

  return (
    <Link
      {...props}
      className={clsx(
        baseClasses,
        "hover:bg-gradient-to-t hover:from-purple-200 hover:to-blue-100",
      )}
    >
      {children}
    </Link>
  );
}
