import { Await, Form, useLoaderData, useSearchParams } from "react-router-dom";
import { ChangeEvent, Suspense, useEffect, useMemo, useRef } from "react";
import { debounce } from "lodash";
import SelectBox from "@/components/forms/select-box";
import { InvoiceStatus } from "@/entities/invoice-status";
import { User } from "@/entities/user";
import { OrderIndexData } from "@/routes/orders/route";
import { snakeCaseToName } from "@/helpers/snake-case-to-name";

export default function OrdersIndexFilters() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { jobillaUsers, countries } = useLoaderData() as OrderIndexData;

  const handleChange = (event: ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setSearchParams((prevParams) => ({
      ...Object.fromEntries(prevParams.entries()),
      page: "1",
      [name]: value,
    }));
  };

  return (
    <Form action="/orders" className="grid lg:grid-cols-2 2xl:grid-cols-4 gap-2 md:gap-4">
      <Suspense
        fallback={
          <SelectBox name="country" label="Country" disabled>
            <option>(Loading country list)</option>
          </SelectBox>
        }
      >
        <Await
          resolve={countries}
          errorElement={
            <SelectBox name="country" label="Country" disabled>
              <option>(Could not load country list)</option>
            </SelectBox>
          }
        >
          {(resolvedCountries) => (
            <SelectBox
              name="country"
              value={searchParams.get("country") ?? "All"}
              label="Country"
              onChange={handleChange}
            >
              <option value="All">All</option>
              {resolvedCountries.map((country: string) => (
                <option key={country} value={country}>
                  {country}
                </option>
              ))}
            </SelectBox>
          )}
        </Await>
      </Suspense>

      <SelectBox
        name="invoice_status"
        value={searchParams.get("invoice_status") ?? ""}
        label="Invoice status"
        onChange={handleChange}
      >
        <option value="">All</option>
        {Object.values(InvoiceStatus).map((status: string) => (
          <option key={status} value={status}>
            {snakeCaseToName(status)}
          </option>
        ))}
      </SelectBox>

      <Suspense
        fallback={
          <SelectBox name="seller" label="Seller" disabled>
            <option>(Loading user data)</option>
          </SelectBox>
        }
      >
        <Await
          resolve={jobillaUsers}
          errorElement={
            <SelectBox name="seller" label="Seller" disabled>
              <option>(Could not load seller list)</option>
            </SelectBox>
          }
        >
          {(resolvedJobillaUsers: User[]) => (
            <SelectBox
              name="seller"
              value={searchParams.get("seller") ?? ""}
              label="Seller"
              onChange={handleChange}
            >
              <option value="">All</option>
              {resolvedJobillaUsers?.map((user: User) => (
                <option key={user.id} value={user.id.toString()}>
                  {user.name}
                </option>
              ))}
            </SelectBox>
          )}
        </Await>
      </Suspense>

      <DateRange handleChange={handleChange} />
    </Form>
  );
}

type DateRangeProps = {
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export function DateRange({ handleChange }: DateRangeProps) {
  const [searchParams] = useSearchParams();
  const dateFromRef = useRef<HTMLInputElement>(null);
  const dateToRef = useRef<HTMLInputElement>(null);

  const debouncedChangeHandler = useMemo(() => {
    return debounce(handleChange, 500);
  }, [handleChange]);

  /*
   * We are only handling the date range inputs manually because their value
   * can be updated rapidly with keyboard interactions, and we don't want to
   * make user wait for the request to complete on each keystroke.
   */
  useEffect(() => {
    if (dateFromRef.current) {
      dateFromRef.current.value = searchParams.get("date_from") ?? "";
    }

    if (dateToRef.current) {
      dateToRef.current.value = searchParams.get("date_to") ?? "";
    }
  }, [searchParams]);

  return (
    <div className="flex flex-col">
      <label htmlFor="order_filters_from" className="font-bold mb-1">
        Date Range
      </label>

      <div className="flex gap-2">
        <input
          ref={dateFromRef}
          type="date"
          id="order_filters_from"
          name="date_from"
          defaultValue={searchParams.get("date_from") ?? ""}
          className="w-full rounded-md ring-stone-300 border-0 ring-1 focus:outline-0 focus:ring-1 focus:ring-purple-500 focus:bg-purple-50 transition duration-200"
          onChange={debouncedChangeHandler}
        />

        <input
          ref={dateToRef}
          type="date"
          id="order_filters_to"
          name="date_to"
          defaultValue={searchParams.get("date_to") ?? ""}
          className="w-full rounded-md ring-stone-300 border-0 ring-1 focus:outline-0 focus:ring-1 focus:ring-purple-500 focus:bg-purple-50 transition duration-200"
          onChange={debouncedChangeHandler}
        />
      </div>
    </div>
  );
}
