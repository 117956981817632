import { useLoaderData } from "react-router-dom";
import { OrderDetailsData } from "@/routes/orders.$order/route";
import { Invoice } from "@/entities/invoice";
import { InvoiceItem } from "@/entities/invoice-item";
import { Table, TableColumn, TableRow } from "@/components/table";
import Date, { DateDisplayType } from "@/components/date";
import StyledLink from "@/components/styled-link";
import { MoneyDisplay } from "@/components/money-display";
import { InvoiceStatus } from "@/entities/invoice-status";

import { InvoiceItemDescription } from "@/components/invoice-item-description";

export function OrderInvoices() {
  const { order } = useLoaderData() as OrderDetailsData;

  return (
    <div className="space-y-6">
      {order.invoices.map((invoice) => (
        <InvoiceBox key={invoice.id} invoice={invoice} />
      ))}
    </div>
  );
}

const invoiceConditions = {
  sending_date: (invoice: Invoice) => (
    <>
      <p className="text-zinc-800 font-bold">Sending date</p>
      <p>We will send the invoice on a given date.</p>
      <p className="inline-block mt-2 px-2 py-1 rounded bg-violet-50 text-violet-600">
        {invoice.date ? (
          <Date date={invoice.date} display={DateDisplayType.DATE} />
        ) : (
          <em>Not set</em>
        )}
      </p>
    </>
  ),
  order_item: () => (
    <>
      <p className="text-zinc-800 font-bold">Order delivery</p>
      <p>
        We will send the invoice as soon as all items in the invoice have been delivered to the
        customer.
      </p>
    </>
  ),
  custom: (invoice: Invoice) => (
    <>
      <p className="text-zinc-800 font-bold">Custom</p>
      <p>Specify your own conditions to be handled manually.</p>
      <p className="mt-2 rounded border border-zinc-300 p-4">{invoice.description}</p>
    </>
  ),
};

function InvoiceBox({ invoice }: { invoice: Invoice }) {
  return (
    <div className="bg-white rounded pt-6 pb-4 px-4 border border-zinc-300">
      <div className="grid grid-cols-3">
        <div className="text-sm space-y-2">
          <p className="text-zinc-500 uppercase font-semibold">Invoice number</p>

          <p>
            <StyledLink to={`/invoices/${invoice.id}`} className="text-xl font-bold">
              #{invoice.numericId}
            </StyledLink>

            {invoice.status === InvoiceStatus.Void ? (
              <span className="text-sm text-red-600 ml-6 font-bold">VOID</span>
            ) : null}
          </p>
        </div>

        <div className="text-sm space-y-2">
          <p className="text-zinc-500 uppercase font-semibold">Condition</p>
          <div>{invoiceConditions[invoice.condition](invoice)}</div>
        </div>

        <div className="text-sm space-y-2">
          <p className="text-zinc-500 uppercase font-semibold">Invoice status</p>
          <p>{invoice.statusForHumans}</p>
        </div>
      </div>

      {invoice.status === InvoiceStatus.Void ? (
        <p className="text-red-600 mt-6 ">
          Voided because at least one item was distributed to another company.{" "}
          {invoice.transferDetails?.newInvoice?.numericId && (
            <span>
              Invoice #{invoice.transferDetails.newInvoice.numericId} was generated for the
              remaining items.
            </span>
          )}
        </p>
      ) : null}

      <div className="mt-8">
        <Table
          columns={[
            { label: "Item #", minimal: true },
            { label: "Product" },
            { label: "Invoice details" },
            { label: "Price", minimal: true, rightAligned: true },
          ]}
        >
          <tbody>
            {invoice.items.map((item) => (
              <InvoiceItemRow key={item.id} item={item} />
            ))}
          </tbody>

          <tfoot>
            <tr>
              <td className="p-4 text-right" colSpan={3}>
                <p className="font-bold">
                  {invoice.status === InvoiceStatus.Void ? (
                    <span className="text-red-600 mr-2 font-bold">VOID</span>
                  ) : null}
                  Total
                </p>
              </td>

              <td className="p-4 text-right whitespace-nowrap">
                <MoneyDisplay amount={invoice.amount} />
              </td>
            </tr>
          </tfoot>
        </Table>
      </div>
    </div>
  );
}

function InvoiceItemRow({ item }: { item: InvoiceItem }) {
  const { order } = useLoaderData() as OrderDetailsData;
  const orderItem = order.items.find((i) => i.id === item.orderItemId);
  const product = orderItem?.product;

  return (
    <TableRow>
      <TableColumn minimal>
        <StyledLink to={`/order-items/${orderItem?.id}`}>
          #{orderItem?.numericId}
        </StyledLink>
      </TableColumn>

      <TableColumn>{product?.title}</TableColumn>

      <TableColumn>
        <InvoiceItemDescription orderItem={orderItem} invoiceItem={item} />
      </TableColumn>

      <TableColumn minimal rightAligned>
        <span className="whitespace-no-wrap">
          <MoneyDisplay amount={item.amount} />
        </span>
      </TableColumn>
    </TableRow>
  );
}
