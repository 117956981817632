import { PencilSquareIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { useCallback, useMemo, useState } from "react";
import { useAsyncValue, useFetcher } from "react-router-dom";
import { OrderItem } from "@/entities/order-item";
import { InvoiceItem } from "@/entities/invoice-item";
import { Job } from "@/entities/job";
import SecondaryButton from "@/components/secondary-button";
import { TextareaField } from "@/components/forms/textarea-field";
import { SaveIcon } from "@/components/icons/save";
import PrimaryButton from "@/components/primary-button";

export function InvoiceItemDescription({ orderItem, invoiceItem }: {
  orderItem: OrderItem | undefined,
  invoiceItem: InvoiceItem | undefined
}) {
  const fetcher = useFetcher();

  const relatedJobs = useAsyncValue() as Job[];
  const relatedJob = relatedJobs?.find(
    (job: Job) => job?.id === orderItem?.campaignContext?.jobId?.toString(),
  );
  const name = relatedJob?.name ?? orderItem?.campaignContext?.jobName ?? null;
  const location = relatedJob?.location ?? orderItem?.campaignContext?.jobLocation ?? null;

  const defaultValueWithFallback = useMemo(() => {
    return invoiceItem?.description ??
      (name ?? "") +
      (name && location ? ", " : "") +
      (location ?? "");
  }, [invoiceItem]);

  const [description, setDescription] = useState(defaultValueWithFallback);
  const [isEditing, setIsEditing] = useState(false);

  const handleSubmit = useCallback(() => {
    fetcher.submit(
      { description: description },
      {
        method: "patch",
        action: `/invoice-items/${invoiceItem?.id}/update-description`,
      },
    );

    setIsEditing(false);
  }, [fetcher, description]);

  const handleCancel = useCallback(() => {
    setDescription(defaultValueWithFallback);
    setIsEditing(false);
  }, [defaultValueWithFallback]);

  return (
    <div className="flex items-center gap-2">
      {isEditing ? (
        <>
          <div className="flex-1">
            <TextareaField
              name="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full h-7 text-sm leading-none"
            />
          </div>

          <PrimaryButton
            onClick={handleSubmit}
            type="submit"
            size="xs"
          >
            <SaveIcon className="w-3 h-3" />
          </PrimaryButton>

          <SecondaryButton
            size="xs"
            onClick={handleCancel}
          >
            <XMarkIcon className="w-3 h-3" />
          </SecondaryButton>
        </>
      ) : (
        <>
          {
            (!!defaultValueWithFallback && fetcher.state === "idle") &&
            <p className="italic">{defaultValueWithFallback}</p>
          }

          {fetcher.state !== "idle" && <p className="italic">Saving...</p>}

          <SecondaryButton
            onClick={() => setIsEditing(true)}
            size="xs"
            className="bg-blue-500 text-white px-2 py-1 rounded"
          >
            <PencilSquareIcon className="w-3 h-3" />
          </SecondaryButton>
        </>
      )}
    </div>
  );
}
