export enum OrderItemStatus {
  New = "new",
  Setup = "setup",
  Active = "active",
  Completed = "completed",
  Transferred = "transferred",
  Cancelled = "cancelled",
}

export const orderItemStatusesAllowedForEditing = [
    OrderItemStatus.New,
    OrderItemStatus.Setup,
];
