import * as Sentry from "@sentry/react";
import { HTTPError } from "ky";
import { usePostHog } from "posthog-js/react";
import React, { useCallback, useEffect } from "react";
import { ActionFunctionArgs, LoaderFunctionArgs, useFetcher, useLoaderData, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { fetchOrder } from "@/api/order";
import { fetchOrderItem, updateOrderItem } from "@/api/order-item";
import { Dialog } from "@/components/dialog";
import SelectBox from "@/components/forms/select-box";
import TextField from "@/components/forms/text-field";
import { SpinnerIcon } from "@/components/icons/spinner";
import SecondaryButton from "@/components/secondary-button";
import SubmitButton from "@/components/submit-button";
import { assertParameterExists } from "@/helpers/loader-guards";
import { productTypes } from "@/helpers/product-types";
import { processYupErrors } from "@/helpers/yup-errors";
import { OrderItemDetailsData } from "@/routes/order-items.$orderItem/route";

export async function loader({ params }: LoaderFunctionArgs) {
  assertParameterExists(params.orderItem);

  const orderItem = await fetchOrderItem(params.orderItem);
  const order = await fetchOrder(orderItem.order?.id ?? orderItem.orderId);

  if (!orderItem.order) {
    Sentry.captureMessage("Order item data does not include order entity", {
      level: "error",
      extra: {
        order_item_id: params.orderItem,
      },
    });

    orderItem.order = order;
  }

  return {
    orderItem,
    order,
  };
}

export async function action({ request, params }: ActionFunctionArgs) {
  assertParameterExists(params.orderItem);

  let input;
  try {
    const inputEntries = Object.fromEntries((await request.formData()).entries());
    input = await Yup.object()
      .shape({
        productType: Yup.string()
          .oneOf(productTypes.map((productType) => productType.id), "Invalid product type")
          .required("Product type is required"),
        amount: Yup.number()
          .transform((value) => Math.round(value * 100))
          .positive("Price must be a positive number")
          .required("Price is required"),
        orderId: Yup.string().required(),
      })
      .validate(inputEntries, { abortEarly: false });
  } catch ( e ) {
    if (e instanceof Yup.ValidationError) {
      return {
        status: 422,
        input: input,
        errors: processYupErrors(e),
      };
    }

    throw e;
  }

  try {
    await updateOrderItem(params.orderItem, {
      amount: input.amount,
      product_type: input.productType,
    });

    return { status: 200 };
  } catch ( e ) {
    if (e instanceof HTTPError && e.response.status === 422) {
      return {
        status: 422,
        errors: (await e.response.json()).errors as Record<string, string[]>,
      };
    }

    return { status: 500 };
  }
}

export default function OrderItemEditDetailsDialog() {
  const { orderItem, order } = useLoaderData() as OrderItemDetailsData;
  const navigate = useNavigate();
  const fetcher = useFetcher();
  const posthog = usePostHog();

  const onFormSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const formData = new FormData(event.currentTarget);

      const originalProductType = (formData.get("originalProductType") as string).toUpperCase();
      const productType = (formData.get("productType") as string).toUpperCase();

      const originalAmount = parseFloat(formData.get("originalAmount") as string);
      const amount = parseFloat(formData.get("amount") as string) * 100;

      posthog.capture("updated order item", {
        order_item_id: orderItem.id,
        converted_product: originalProductType !== productType,
        changed_price: originalAmount !== amount,
        product_original: originalProductType,
        product_new: productType,
        amount_original: originalAmount,
        amount_new: amount,
      });

      fetcher.submit(event.currentTarget, { method: "post" });
    }, [fetcher, orderItem.id]);

  const redirectWithFallback = useCallback(() => {
    if (window.history.state?.idx > 0) {
      navigate(-1);
    } else {
      navigate(`/order-items/${orderItem.id}`);
    }
  }, [window.history.state?.idx, orderItem?.id]);

  useEffect(() => {
    if (fetcher?.data?.status !== 200) {
      return;
    }

    redirectWithFallback();
  }, [fetcher?.data?.status]);

  return (
    <Dialog
      title={`Edit order item #${orderItem.numericId}`}
      isOpen
      onClose={redirectWithFallback}
      description="You may edit an order item product type and price as you need.
      Be aware that products have different prices if you decide to convert an item."
    >
      <fetcher.Form
        onSubmit={onFormSubmit}
        method="post"
        noValidate
        className={"flex flex-col gap-y-4"}
      >
        <input type="hidden" name="orderId" value={order?.id} />
        <input type="hidden" name="originalProductType" value={orderItem?.product?.sku} />
        <input type="hidden" name="originalAmount" value={orderItem?.amount.amount} />
        {orderItem.product?.isProductWithToken && (
          <SelectBox
            name="productType"
            label={"Product type"}
            defaultValue={orderItem.product.sku.toLowerCase()}
            errors={fetcher.data?.errors?.productType}
            description="Choosing a different product here will convert the existing one."
          >
            {productTypes.map((productType) => (
              <option
                key={productType.id}
                value={productType.id}
              >
                {productType.name}
              </option>
            ))}
          </SelectBox>
        )}

        <TextField
          label={"Price"}
          inputMode="numeric"
          pattern="[0-9]+\.[0-9]*"
          name="amount"
          defaultValue={orderItem.amount.amountWithoutCents}
          disabled={fetcher.state !== "idle"}
          className="w-28"
          errors={fetcher.data?.errors?.amount}
          description={"Price in EUR"}
        />

        <div className="mt-8 flex items-center justify-center space-x-4">
          <SubmitButton
            label="Save"
            size="lg"
            labelWhenSubmitting={
              <SpinnerIcon className="animate-spin h-4 w-4 text-white" />
            }
          />

          <SecondaryButton type="button" onClick={redirectWithFallback} size="lg">
            Cancel
          </SecondaryButton>
        </div>
      </fetcher.Form>
    </Dialog>
  );
}
