import { PencilIcon, PlusIcon, ReceiptPercentIcon } from "@heroicons/react/20/solid";
import * as Sentry from "@sentry/react";
import React, { Suspense, useCallback } from "react";
import { Await, LoaderFunctionArgs, Outlet, useLoaderData } from "react-router-dom";
import { OrderDetailsData } from "@/routes/orders.$order/route";
import { fetchCompanyNotes } from "@/api/company-notes";
import { fetchJob } from "@/api/job";
import { fetchJobillaUsers } from "@/api/jobilla-users";
import { fetchOrder } from "@/api/order";
import { fetchOrderItem } from "@/api/order-item";
import { fetchUserPermissionFor } from "@/api/permissions";
import { fetchAllUnits } from "@/api/unit";
import * as Page from "@/components/page";
import PrimaryAnchor from "@/components/primary-anchor";
import { PrimaryLink } from "@/components/primary-link";
import { assertParameterExists } from "@/helpers/loader-guards";
import { optional } from "@/helpers/loader-optional";
import { CampaignDetails } from "@/routes/order-items.$orderItem/campaign-details";
import { Boosters } from "@/routes/order-items.$orderItem/boosters";
import { CompanyNotes } from "@/routes/order-items.$orderItem/company-notes";
import { ContactPerson } from "@/routes/order-items.$orderItem/contact-person";
import { Creators } from "@/routes/order-items.$orderItem/creators";
import { Details } from "@/routes/order-items.$orderItem/details";
import { Revoke } from "@/routes/order-items.$orderItem/revoke";
import { StatusSwitcher } from "@/routes/order-items.$orderItem/status-switcher";

export { ErrorBoundary } from "./error-boundary";

export type OrderItemDetailsData = Awaited<ReturnType<typeof loader>>;

export async function loader({ params }: LoaderFunctionArgs) {
  assertParameterExists(params.orderItem);

  const orderItem = await fetchOrderItem(params.orderItem);
  const order = await fetchOrder(orderItem.order?.id ?? orderItem.orderId);
  const invoice = [...order.invoices].reverse().find((invoice) =>
    invoice.items.some((item) => item.orderItemId === orderItem.id));

  if (!orderItem.order) {
    Sentry.captureMessage("Order item data does not include order entity", {
      level: "error",
      extra: {
        order_item_id: params.orderItem,
      },
    });

    orderItem.order = order;
  }

  return {
    orderItem,
    order,
    job: orderItem.campaignContext?.jobId
      ? optional(fetchJob(orderItem.campaignContext.jobId))
      : null,
    invoice: invoice,
    invoiceItem: invoice?.items.find((item) => item.orderItemId === orderItem.id),
    permissionToRevokeCampaign: fetchUserPermissionFor(
      "revoke_campaign_request",
      "order",
      order.id,
    ),
    units: fetchAllUnits(),
    jobillaUsers: fetchJobillaUsers(),
    // At least one of those properties will be present. Terrible interface
    // design, I know... We will update these API result types some time in the
    // future to use individual return types for each of these endpoints.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    companyNote: fetchCompanyNotes((order.company?.id ?? order.companyId)!),
    canUserModify: fetchUserPermissionFor("update", "order", order.id),
  };
}

export default function OrderItem() {
  const { orderItem, invoice } = useLoaderData() as OrderItemDetailsData;
  const { canUserModify } = useLoaderData() as OrderDetailsData;

  const getDisabledSplitButtonTooltipText = useCallback((hasPermission: boolean): string => {
    if (!hasPermission) {
      return "You don't have permission to split this order item";
    }

    if (!orderItem.canBeEdited) {
      return orderItem.cannotBeActedOnMessage("Split");
    }

    if (invoice && !invoice.canBeEdited) {
      return invoice.cannotBeActedOnMessage("Split");
    }

    return "Only Jobilla Target items that cost 1000 or more and being whole numbers can be split";
  }, [orderItem, invoice]);

  return (
    <Page.Root>
      <Page.Heading
        backLink={
          <Page.GoBackLink to={`/order-pipeline/${orderItem.product?.id ?? orderItem.productId}`}>
            Back to pipeline
          </Page.GoBackLink>
        }
      >
        <div className="flex justify-between items-center gap-4">
          <Page.Title>
            {orderItem.product?.title ? `Order item: ${orderItem.product.title}` : "Order item"}
          </Page.Title>

          <div className="flex items-end gap-4">
            <StatusSwitcher />
          </div>
        </div>
      </Page.Heading>

      <Page.Content multiColumnLayout>
        <Page.Main>
          {orderItem.product?.isTargetProduct && (
            <div className="flex flex-col gap-12 bg-white rounded shadow-sm p-6 pb-12">
              <>
                <Section title="">
                  <div className="flex gap-4 md:flex-wrap justify-between items-center">
                    <p className="text-2xl font-bold text-gray-600">Campaign details</p>
                    <div className="flex gap-x-4 md:w-auto items-center">
                      {
                        !orderItem.isDistributedOrCancelled
                        && (
                          <>
                            <PrimaryLink
                              to={`/order-items/${orderItem.id}/campaign-request`}
                              size="lg"
                            >
                              <PencilIcon className="relative h-3.5 w-3.5 mr-1" />
                              Edit
                            </PrimaryLink>
                            <CreateRecruitmentButton />
                          </>
                        )}
                    </div>
                  </div>
                  <CampaignDetails />
                </Section>

                {orderItem.campaignContext && (
                  <>
                    <Section title="Booster">
                      <Boosters />
                    </Section>

                    <Section title="Campaign creator's details">
                      <Creators />
                    </Section>

                    <Section title="Campaign contact person">
                      <ContactPerson />
                    </Section>
                  </>
                )}
              </>
            </div>
          )}

          <div className="flex flex-col gap-6 bg-white rounded shadow-sm p-6">
            <div className="flex items-center justify-between gap-4">
              <p className="text-2xl font-bold text-gray-600">Details</p>
              <Suspense fallback={null}>
                <Await resolve={canUserModify}>
                  {(hasPermission) => (
                    <div className="flex gap-x-4">
                      {orderItem?.product?.isTargetProduct && (
                        <PrimaryLink
                          disabled={!orderItem.isSplitEnabled(hasPermission, invoice)}
                          disabledTooltipText={getDisabledSplitButtonTooltipText(hasPermission)}
                          to={`/order-items/${orderItem.id}/split`}
                          preventScrollReset
                          size="lg"
                        >
                          <ReceiptPercentIcon className="relative h-3.5 w-3.5 mr-1" />
                          Split Order Item
                        </PrimaryLink>
                      )}
                      <PrimaryLink
                        disabled={
                          !hasPermission
                          || !orderItem.canBeEdited
                          || !invoice?.canBeEdited
                        }
                        disabledTooltipText={
                          !hasPermission && "You don't have permission to edit this order item"
                          || !orderItem.canBeEdited && orderItem.cannotBeActedOnMessage()
                          || !invoice?.canBeEdited && invoice?.cannotBeActedOnMessage()
                        }
                        to={`/order-items/${orderItem.id}/edit-details`}
                        preventScrollReset
                        size="lg"
                      >
                        <PencilIcon className="relative h-3.5 w-3.5 mr-1" />
                        Edit
                      </PrimaryLink>
                    </div>
                  )}
                </Await>
              </Suspense>
            </div>
            <Details />
          </div>

          {orderItem.product?.isTargetProduct && <Revoke />}
        </Page.Main>

        <Page.ColumnSeparator />

        <Page.Sidebar>
          <CompanyNotes />
        </Page.Sidebar>
      </Page.Content>

      <Outlet />
    </Page.Root>
  );
}

function Section({ title, children }: { title: string; children: React.ReactNode }) {
  return (
    <section className="flex flex-col gap-6">
      <p className="text-2xl font-bold text-gray-600">{title}</p>
      {children}
    </section>
  );
}

function CreateRecruitmentButton() {
  const { orderItem } = useLoaderData() as OrderItemDetailsData;

  if (orderItem.campaignContext?.jobId) {
    return (
      <PrimaryAnchor
        href={`${import.meta.env.VITE_TAS_APP_URL}/c/${orderItem.order?.company?.id}/jobs/${
          orderItem.campaignContext.jobId
        }`}
        size="lg"
        target="_blank"
      >
        Go to recruitment
      </PrimaryAnchor>
    );
  }

  return (
    <PrimaryLink size="lg" to={`/order-items/${orderItem.id}/recruitment`}>
      <PlusIcon className="w-3 h-3" />
      <span>Create recruitment</span>
    </PrimaryLink>
  );
}
