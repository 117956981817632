import { Fragment, Suspense } from "react";
import { Await, useLoaderData } from "react-router-dom";
import * as DL from "@/components/definition-list";
import { User } from "@/entities/user";
import { StyledAnchor } from "@/components/styled-anchor";
import { OrderItemDetailsData } from "@/routes/order-items.$orderItem/route";

export function Creators() {
  const { order, jobillaUsers } = useLoaderData() as OrderItemDetailsData;

  return (
    <DL.Root>
      {order.users.map((orderUser, index) => {
        return (
          <Fragment key={orderUser.userId ?? index}>
            <DL.Item label="Name">
              <Suspense
                fallback={
                  <>
                    #{orderUser.userId} <i>(Loading user data)</i>
                  </>
                }
              >
                <Await resolve={jobillaUsers}>
                  {(resolvedJobillaUsers: User[]) => {
                    const user = resolvedJobillaUsers.find((user) => user.id === orderUser.userId);

                    return user ? (
                      user.name
                    ) : (
                      <>
                        #{orderUser.userId} <i>(User data not found)</i>
                      </>
                    );
                  }}
                </Await>
              </Suspense>
            </DL.Item>

            <DL.Item label="Email address">
              <Suspense>
                <Await resolve={jobillaUsers}>
                  {(resolvedJobillaUsers: User[]) => {
                    const user = resolvedJobillaUsers.find((user) => user.id === orderUser.userId);

                    return (
                      user && (
                        <StyledAnchor href={`mailto: ${user.email}`}>{user.email}</StyledAnchor>
                      )
                    );
                  }}
                </Await>
              </Suspense>
            </DL.Item>
          </Fragment>
        );
      })}
    </DL.Root>
  );
}
