import { PropsWithChildren } from "react";
import clsx from "clsx";

export type TableColumn = {
  label: string;
  minimal?: boolean;
  rightAligned?: boolean;
};

export interface TableArgs {
  columns?: TableColumn[];
}

export function Table({ columns, children }: PropsWithChildren<TableArgs>) {
  return (
    <div className="w-full overflow-auto rounded-md border">
      <table className="w-full text-sm bg-white">
        {Array.isArray(columns) && (
          <thead>
            <tr className="text-gray-900 text-left border-b-2">
              {columns.map((column) => (
                <th
                  className={clsx(
                    "p-4 font-bold",
                    column.minimal ? "w-1" : "",
                    column.rightAligned ? "text-right" : "",
                  )}
                  key={column.label}
                >
                  {column.label}
                </th>
              ))}
            </tr>
          </thead>
        )}

        {children}
      </table>
    </div>
  );
}
