import { Suspense } from "react";
import { Await, useAsyncValue, useLoaderData } from "react-router-dom";
import { capitalize } from "lodash";
import StyledLink from "@/components/styled-link";
import { InvoiceItem } from "@/entities/invoice-item";
import { Product } from "@/entities/product";
import { Job } from "@/entities/job";
import { OrderItem } from "@/entities/order-item";
import { Table, TableColumn, TableRow } from "@/components/table";
import { MoneyDisplay } from "@/components/money-display";
import { InvoiceStatus } from "@/entities/invoice-status";
import { InvoiceItemDescription } from "@/components/invoice-item-description";
import { InvoiceDetailsData } from "./loader";

export function InvoiceItems() {
  const { invoice } = useLoaderData() as InvoiceDetailsData;

  return (
    <Table
      columns={[
        { label: "Item #" },
        { label: "Product" },
        { label: "Invoice details" },
        { label: "Status" },
        { label: "Amount" },
      ]}
    >
      <tbody>
      {invoice.items.map((item) => (
        <InvoiceItemRow key={item.id} item={item} />
      ))}
      </tbody>

      {invoice.items.length && (
        <tfoot>
        <tr>
          <td />
          <td />

          <td className="p-4 font-bold">
            Total
          </td>

          <td className="p-4">
            <MoneyDisplay amount={invoice.amount} />
          </td>
        </tr>
        </tfoot>
      )}
    </Table>
  );
}

function InvoiceItemRow({ item }: { item: InvoiceItem }) {
  const { orderItems, products, invoice } = useLoaderData() as InvoiceDetailsData;
  const relatedJobs = useAsyncValue() as Job[];

  const product = products.find((product: Product) => product.id === item.productId);
  const orderItem = orderItems.find((orderItem: OrderItem) => orderItem.id === item.orderItemId);

  return (
    <TableRow className="bg-white hover:bg-indigo-50">
      <TableColumn>
        <StyledLink className="font-bold" to={`/order-items/${item.orderItemId}`}>
          #{orderItem?.numericId}
        </StyledLink>
      </TableColumn>

      <TableColumn>
        <div className="flex justify-between">
          <div>
            {product?.title}
            {invoice.status === InvoiceStatus.Void &&
              " - distributed to " + (invoice?.transferDetails?.toCompany?.name ?? "related company")}
          </div>
        </div>
      </TableColumn>

      <TableColumn>
          <p className="italic">
            <Suspense
              key={item.id}
              fallback={
                <p>
                  <em>Loading invoice details...</em>
                </p>
              }
            >
              <Await resolve={relatedJobs}>
                <InvoiceItemDescription orderItem={orderItem} invoiceItem={item} />
              </Await>
            </Suspense>
          </p>
      </TableColumn>

      <TableColumn>
        <div className="flex flex-wrap gap-2 items-center">
          {invoice.status !== InvoiceStatus.Void ? capitalize(orderItem?.status) : "-"}
        </div>
      </TableColumn>

      <TableColumn>
        <div className="flex flex-wrap gap-2 items-center whitespace-nowrap">
          <MoneyDisplay amount={item.amount} />
        </div>
      </TableColumn>
    </TableRow>
  );
}
