import { useCallback, useState } from "react";
import { useFetcher } from "react-router-dom";
import { EntityBuilder, PartialPropsJson } from "@jobilla/entity";
import * as Yup from "yup";
import TextField from "@/components/forms/text-field";
import SelectBox from "@/components/forms/select-box";
import { Currency } from "@/entities/currency";
import { PaymentTerms } from "@/entities/payment-terms";
import { COUNTRIES } from "@/helpers/countries";
import SubmitButton from "@/components/submit-button";
import { Billing } from "@/entities/billing";
import { finvoiceOperators } from "@/helpers/finvoice-operators";
import { BillingAddress } from "@/entities/billing-address";
import SecondaryButton from "@/components/secondary-button";
import { VatField } from "@/components/billing-form/vat-field";
import { useOnFetcherSuccess } from "@/hooks/fetcher-hooks";
import { DeleteButton } from "@/components/billing-form/delete-button";

type BillingFormProps = {
  billing?: Billing;
  companyId: number;
  onSuccess?: (billing: Billing) => void;
  onCancel: () => void;
};

export function BillingForm({ billing, companyId, onCancel, onSuccess }: BillingFormProps) {
  const fetcher = useFetcher();
  const [name, setName] = useState(billing?.name ?? "");
  const [finvoiceOperator, setFinvoiceOperator] = useState(billing?.finvoiceOperator ?? "");
  const [address, setAddress] = useState(
    billing?.address ??
      EntityBuilder.buildOne(BillingAddress, {
        country: "Finland",
      }),
  );

  useOnFetcherSuccess(fetcher, () => {
    if (fetcher.data?.status === 200) {
      onSuccess?.(fetcher.data?.billing);
    }
  });

  const setAddressPartially = useCallback(
    (data: PartialPropsJson<BillingAddress>) => {
      setAddress((address) => EntityBuilder.buildOne(BillingAddress, { ...address, ...data }));
    },
    [address, setAddress],
  );

  const updateDetailsBasedOnVAT = useCallback(
    (billingOfVat: Billing, override = false) => {
      if (override || !name) {
        setName(billingOfVat.name);
      }

      if (override || !address.line1) {
        setAddress(billingOfVat.address);
      }
    },
    [name, address],
  );

  const disabled = fetcher.state !== "idle";
  const errors = fetcher.data?.errors;

  return (
    <fetcher.Form
      method="post"
      action={billing ? `/api/billings/${billing.id}` : "/api/billings/create"}
      noValidate
    >
      <input type="hidden" name="company_id" value={companyId} />
      <input type="hidden" name="id" defaultValue={billing?.id} />

      <fieldset disabled={disabled} className="space-y-4">
        <VatField
          billing={billing}
          disabled={disabled}
          errors={errors?.vat}
          onResultFound={updateDetailsBasedOnVAT}
          required={["Finland", "Germany"].includes(address.country)}
        />

        <div className="grid grid-cols-2 gap-3">
          <TextField
            label="Company official name"
            required
            name="name"
            id="company_official_name"
            value={name}
            onInput={(event) => setName(event.currentTarget.value)}
            errors={errors?.name}
          />

          <TextField
            label="Invoicing e-mail"
            required
            name="email"
            id="invoicing_e-mail"
            description="E-mail used for invoice related communication."
            defaultValue={billing?.email}
            errors={errors?.email}
          />
        </div>

        <TextField
          label="Reference / Purchase order #"
          name="reference"
          id="reference/purchase_order"
          defaultValue={billing?.reference}
          errors={errors?.reference}
        />

        <div className="grid grid-cols-2 gap-3">
          <SelectBox
            name="currency"
            label="Currency"
            defaultValue={billing?.currency}
            errors={errors?.currency}
          >
            {Object.keys(Currency).map((currency) => (
              <option key={currency} value={currency}>
                {currency}
              </option>
            ))}
          </SelectBox>

          <SelectBox
            name="payment_terms"
            label="Default payment terms"
            defaultValue={billing?.paymentTerms}
          >
            {Object.values(PaymentTerms)
              .filter((value) => typeof value === "number")
              .map((paymentTerm) => (
                <option key={paymentTerm} value={paymentTerm}>
                  {paymentTerm}
                </option>
              ))}
          </SelectBox>
        </div>

        <TextField
          label="Invoicing address"
          required
          name="address_line1"
          id="invoicing_address"
          errors={errors?.address_line1}
          value={address.line1 ?? ""}
          onChange={(event) => setAddressPartially({ line_1: event.currentTarget.value })}
        />

        <div className="grid grid-cols-3 gap-3">
          <TextField
            label="Postal code"
            required
            name="address_postal_code"
            id="postal_code"
            errors={errors?.address_postal_code}
            value={address.postalCode ?? ""}
            onChange={(event) => setAddressPartially({ postal_code: event.currentTarget.value })}
          />

          <TextField
            label="City"
            required
            name="address_city"
            id="address_city"
            errors={errors?.address_city}
            value={address.city ?? ""}
            onChange={(event) => setAddressPartially({ city: event.currentTarget.value })}
          />

          <SelectBox
            label="Country"
            name="address_country"
            id="country"
            errors={errors?.address_country}
            value={address.country ?? ""}
            onChange={(event) => setAddressPartially({ country: event.currentTarget.value })}
          >
            {COUNTRIES.map((country: string) => (
              <option key={country} value={country}>
                {country}
              </option>
            ))}
          </SelectBox>
        </div>

        <SelectBox
          label="eInvoice operator"
          name="finvoiceOperator"
          id="finvoiceOperator"
          errors={errors?.finvoiceOperator}
          value={finvoiceOperator}
          onChange={(event) => setFinvoiceOperator(event.currentTarget.value)}
        >
          <option value="">No operator</option>

          {finvoiceOperators.map((operator) => (
            <option key={operator.name} value={operator.id}>
              {operator.name}
            </option>
          ))}
        </SelectBox>

        <TextField
          label="eInvoice address"
          name="finvoiceAddress"
          id="finvoiceAddress"
          required={!!finvoiceOperator}
          defaultValue={billing?.finvoiceAddress}
          errors={errors?.finvoiceAddress}
        />

        <div>
          <label htmlFor="default_billing" className="inline-flex items-center space-x-2">
            <input id="default_billing" type="checkbox" name="isDefault" />
            <p>Use these as the default billing details.</p>
          </label>
        </div>
      </fieldset>

      <div className="mt-8 flex items-center justify-center space-x-4">
        <SubmitButton size="lg" disabled={disabled} isSubmitting={fetcher.state === "submitting"} />

        <SecondaryButton onClick={onCancel} type="button" size="lg">
          Cancel
        </SecondaryButton>

        {billing?.id ? <DeleteButton billing={billing} /> : null}
      </div>
    </fetcher.Form>
  );
}

export const validationSchema = Yup.object().shape({
  company_id: Yup.number().required("Company is required"),
  name: Yup.string().required("Name is required"),
  email: Yup.string().required("Email is required").email("Please type a valid email address"),
  reference: Yup.string(),
  payment_terms: Yup.number().required("Payment terms is required"),
  address_line1: Yup.string().required("Address is required"),
  address_postal_code: Yup.string().required("Postal code is required"),
  address_city: Yup.string().required("City is required"),
  address_country: Yup.string().required("Country is required"),
  vat: Yup.string()
    .matches(/^[A-Z\d-]+$/, "VAT can only contain capital letters, numbers and hyphens")
    .when("address_country", (addressCountry, schema) => {
    return ["Finland", "Germany"].includes(addressCountry)
      ? schema.required("VAT is required when country is Finland or Germany.")
      : schema;
  }),
  finvoiceAddress: Yup.lazy(() =>
    Yup.string().when("finvoiceOperator", {
      is: (finvoiceOperator: string) => finvoiceOperator !== "",
      then: Yup.string().required("Address is required when eInvoice operator is selected."),
    }),
  ),
  finvoiceOperator: Yup.lazy(() =>
    Yup.string().when("finvoiceAddress", {
      is: (finvoiceAddress: string) => finvoiceAddress !== "",
      then: Yup.string().required(
        "You need to choose an eInvoice operator when an eInvoice address is provided.",
      ),
    }),
  ),
});
