export enum InvoiceStatus {
  New = "new",
  ConfirmBillingDetails = "confirm_billing_details",
  ReadyToSend = "ready_to_send",
  Void = "void",
  Sent = "sent",
  PendingInformation = "pending_information",
  ReminderSent = "reminder_sent",
  PendingDeletion = "pending_deletion",
  Paid = "paid",
}

/*
 * The main objective with this list is to prevent the user from choosing
 * the defined statuses as a new invoice status.
 */
export const readonlyInvoiceStatuses = [InvoiceStatus.ReminderSent, InvoiceStatus.Void];

export const invoiceStatusesAllowedForEditing: InvoiceStatus[] = [
  InvoiceStatus.New,
  InvoiceStatus.ConfirmBillingDetails,
  InvoiceStatus.ReadyToSend,
  InvoiceStatus.PendingInformation,
];

