import { Entity, Type } from "@jobilla/entity";
import { Money } from "./money";

export class InvoiceItem extends Entity {
  id!: string;
  invoiceId!: string;
  orderItemId!: string;
  productId!: string;
  title!: string;
  description!: string;
  @Type(Money)
  amount!: Money;
  uuid!: string;
}
