import { EntityBuilder, PropsJson } from "@jobilla/entity";
import { api } from "@/api/base";
import { InvoiceItem } from "@/entities/invoice-item";

export async function updateInvoiceItemDetails(
  invoiceItemId: string,
  description: string,
) {
  const response = await api
    .patch(`v1/invoices/items/${invoiceItemId}`, {
      json: {
        description,
      },
    })
    .json<{ data: PropsJson<InvoiceItem> }>();

  return EntityBuilder.buildOne(InvoiceItem, response.data);
}
