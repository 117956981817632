import { ActionFunctionArgs } from "react-router-dom";
import * as Yup from "yup";
import { HTTPError } from "ky";
import { processYupErrors } from "@/helpers/yup-errors";
import { assertParameterExists } from "@/helpers/loader-guards";
import { updateInvoiceItemDetails } from "@/api/invoice-item";

export async function action({ params, request }: ActionFunctionArgs) {
  assertParameterExists(params.invoiceItem);

  let input;

  try {
    input = await Yup.object()
      .shape({
        description: Yup.string(),
      })
      .validate(Object.fromEntries((await request.formData()).entries()), { abortEarly: false });
  } catch (e) {
    if (e instanceof Yup.ValidationError) {
      return {
        status: 422,
        errors: processYupErrors(e),
      };
    }

    throw e;
  }

  try {
    await updateInvoiceItemDetails(params.invoiceItem, input.description ?? "");

    return { status: 200 };
  } catch (e) {
    if (e instanceof HTTPError && e.response.status === 422) {
      return {
        status: 422,
        errors: (await e.response.json()).errors,
      };
    }

    return { status: 500 };
  }
}
